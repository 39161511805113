<template>
    <div class="column quiz-container desktop-11 touch-12 mx-auto mt-6 px-6 pb-6 mt-0 pt-0">
        <ShadowBox :bg="bg">
            <div class="column is-9 has-text-centered" data-options-box>
                <slot name="title"></slot>

                <div class="columns is-multiline py-6" :class="size === '-small' ? 'is-mobile' : ''">
                    <div class="column" :class="`is-${columnSize}`" v-for="(option, index) in options[step]" :key="option.label" @click="$emit('optionClicked', {step, option})">
                        <OptionBox
                            :option="option"
                            :index="index"
                            :size="size"
                            :active="isSelected(option)"
                        />
                    </div>
                </div>

                <button class="button w-100-mobile is-dark" v-if="step > 1" @click="$emit('previousStep')">Indietro</button>
                <button class="button w-100-mobile is-fd-primary ml-4 quiz-button-down-mobile" :class="{'is-disabled': selected.length === 0}" :disabled="selected.length === 0" @click="$emit('nextStep')">Avanti</button>
            </div>
            <div class="column is-3">
                <div class="box quiz-configuration-box">
                    <div class="mx-auto mobile-toggler hidden-desktop" />
                    <p class="quiz-configuration-title">La tua configurazione</p>
                    <p class="quiz-configuration-text has-text-danger mt-4" v-if="currentSelected.length === 0">
                        Nessuna opzione selezionata
                    </p>
                    <div v-for="option in currentSelected" :key="option.label">
                        <hr class="hidden-mobile" style="background: #282828;">
                        <div class="columns is-mobile is-vcentered px-3-mobile my-2-mobile">
                            <div class="column quiz-configuration-icon-box" :style="`background-image: url('https://stage.fiberdroid.it/pages/quiz/${option.icon}-bg.svg')`">
                                <img :src="`https://stage.fiberdroid.it/pages/quiz/${option.addIcon ? option.addIcon : option.icon}.${option.ext ? option.ext : `svg`}`" />
                            </div>
                            <div class="column is-8">
                                <p class="quiz-configuration-text">{{ option.addLabel ?? option.label }}</p>
                            </div>
                            <div class="column is-2">
                                <!--<img class="is-clickable" src="https://stage.fiberdroid.it/pages/quiz/delete.svg" @click="toggleSelect(option)"/>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12 has-text-right hidden-mobile" style="display: flex">
                <p class="quiz-step-text" style="margin-top: auto; width: 100%; text-align: right">{{ step }}/6</p>
            </div>
        </ShadowBox>
    </div>
</template>

<script>
import ShadowBox from "@/helpers/ShadowBox";
import OptionBox from "@/helpers/quiz/OptionBox";
export default {
    name: "StepBox",
    components: {OptionBox, ShadowBox},
    props: {
        step: {
            type: [Number,String],
            required: true
        },
        options: {
            type: Object,
            required: true
        },
        selected: {
            type: Array,
            required: true,
        },
        bg: {
            type: String,
            required: true,
        }
    },
    computed: {
        size() {
            switch (this.options[this.step].length) {
                case 6:
                    return "-small"
                case 3:
                    return "-medium"
                case 2:
                default:
                    return ""
            }
        },
        columnSize() {
            switch (this.options[this.step].length) {
                case 6:
                case 3:
                    return "4"
                case 2:
                default:
                    return "6"
            }
        },
        currentSelected() {
            return this.selected.filter(el => {
                return (
                    parseInt(el.step) < parseInt(this.step)
                ) && (
                    el.visible !== false
                )
            })
        }
    },
    methods: {
        isSelected(option) {
            return !!this.selected.find(el => el.label === option.label)
        }
    }
}
</script>

<style scoped>

</style>
