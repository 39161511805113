<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div :class="opened ? 'mb-6' : 'mb-2'">

        <TechnologyTitle title="4G/5G" :type="type" :url="url" />
        <div v-if="opened">
            <TechnologyHeader :columns="['Copertura', 'Stato', 'Richiesta']"/>

            <div class="columns is-multiline is-vcentered coverage-row">
                <div class="column">
                    <div style="display: flex; justify-content: center; gap: 10px">
                        <p class="coverage-technology-name">
                            4G
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="coverage-tag coverage-tag-success">
                        DISPONIBILE
                    </div>
                </div>
                <div class="column">
                </div>
            </div>

            <div class="columns is-multiline is-vcentered coverage-row">
                <div class="column">
                    <div style="display: flex; justify-content: center; gap: 10px">
                        <p class="coverage-technology-name">
                            5G
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="coverage-tag coverage-tag-success">
                        DISPONIBILE
                    </div>
                </div>
                <div class="column">
                    <div class="button coverage-button is-dark is-small">Invia richiesta</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import technologies from "@/helpers/coverage/coverageResults/technologies";

export default {
    name: "GResult",
    mixins: [technologies]
}
</script>

<style scoped>

</style>
