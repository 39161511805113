<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div :class="opened ? 'mb-6' : 'mb-2'">

        <TechnologyTitle :title="data[0].technology.family.description" :type="type" :url="url" />
        <div v-if="opened" >
            <TechnologyHeader :columns="['Copertura', 'Stato', 'Vel. Stimata', 'Vel. Max.', 'Distanza', 'Mappa']"/>

            <div class="columns is-vcentered coverage-row" :key="techName" v-for="techName in technologyNames">
                <div class="column is-2">
                    <div style="display: flex; justify-content: center; gap: 10px">
                        <img :src="groupedTechnologies[techName][0].agcom_sticker_url" style="width: 19px; height: 19px; margin: auto 0">
                        <p class="coverage-technology-name">
                            {{ techName }}
                        </p>
                    </div>
                </div>
                <div class="column is-2">
                    <div class="coverage-tag coverage-tag-success" v-if="somethingActive(groupedTechnologies[techName])">
                        DISPONIBILE
                    </div>
                    <div class="coverage-tag coverage-tag-danger" v-else>
                        NON DISPONIBILE
                    </div>
                </div>
                <div class="column is-8">
                    <div class="columns is-vcentered" :key="technology.carrier.name" v-for="technology in groupedTechnologies[techName]" v-if="(!Array.isArray(technology.data) || technology.data.length > 0) && technology.data?.edr_info?.particella">
                        <div class="column is-3 coverage-technology-text">
                            <p class="coverage-mobile-technology-text hidden-tablet-only hidden-desktop">
                                Vel. Stimata
                            </p>
                            <div v-if="technology.data.velocita_dettaglio" v-html="detailNetworkSpeed(technology.data.velocita_dettaglio)" />
                            <p v-else>-</p>
                        </div>
                        <div class="column is-3 coverage-technology-text">
                            <p class="coverage-mobile-technology-text hidden-tablet-only hidden-desktop">
                                Vel. Max
                            </p>
                            {{ technology.data.max_speed | network_speed }}
                        </div>
                        <div class="column is-3 coverage-technology-text">
                            <p class="coverage-mobile-technology-text hidden-tablet-only hidden-desktop">
                                Distanza
                            </p>
                            {{ technology.data.distanza | distance }}
                        </div>
                        <div class="column is-3 coverage-technology-text">
                            <a @click="$emit('showMap', technology)" class="coverage-technology-link is-size-7">
                                {{ technology.data.tipo_edr }} | {{ technology.data.edr_info?.particella }} {{ technology.data.edr_info?.via }} {{ technology.data.edr_info?.civico }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Maybe ?
            <CoverageFullInfo :data="data.map(el => {
                el = {...el}
                delete el.agcom_sticker_url
                el.carrier = el.carrier.name
                el.technology = el.technology.family.description + ' | ' + el.technology.description

                return el
            })"/>
            -->

        </div>
    </div>
</template>

<script>
import technologies from "@/helpers/coverage/coverageResults/technologies";

export default {
    name: "FttcResult",
    mixins: [technologies]
}
</script>

<style scoped>

</style>
