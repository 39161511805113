<template>
    <div class="box coverage-technology-box is-clickable" :data-coverage-type="type">
        <img :src="`${this.url}/pages/icons/chevron-down-safari.svg`">
        <p class="coverage-technology-title ml-5">{{ title }}</p>
    </div>
</template>

<script>
export default {
    name: "TechnologyTitle",
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
          type: String,
          default: "",
        },
        url: {
          type: String,
          required: true,
        }
    }
}
</script>

<style scoped>

</style>
