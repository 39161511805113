<template>
    <div
        class="box"
        :class="[
                                `quiz-option-box-shadow${size}`,
                                `quiz-gradient-${option.gradient}`,
                                {
                                    [`quiz-gradient-${option.gradient}-active`] : active,

                                    'ml-auto': mlAuto,
                                    'mx-auto': (size === '-small' || size === '-medium') && index % 3 === 1,
                                    'mr-auto': mrAuto,
                                }
                            ]"
    >
        <div class="box" :class="`quiz-option-box${size}`">
            <div :class="`quiz-option-wrapper${size}`">
                <div>
                    <img class="quiz-option-image" :src="`https://stage.fiberdroid.it/pages/quiz/${option.icon}.svg`"/>
                    <p class="quiz-text-option" v-html="option.label" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OptionBox",
    computed: {
        mlAuto() {
            return (
                (this.size === '-small' || this.size === '-medium') && this.index % 3 === 0
            ) || (
                !this.size && this.index % 2 === 0
            )
        },
        mrAuto() {
            return (
                (this.size === '-small' || this.size === '-medium') && this.index % 3 === 2
            ) || (
                !this.size && this.index % 2 === 1
            )
        }
    },
    props: {
        option: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true,
        },
        size: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: true
        }
    },
}
</script>

<style scoped>

</style>
