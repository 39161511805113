<template>
    <div id="render" style="margin: auto; width: 1300px; height: 720px">
    </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {RoomEnvironment} from "three/examples/jsm/environments/RoomEnvironment";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export default {
    name: "FiberdroidModel",
    data() {
        return {
            options: {
                width: 1300,
                height: 620,
            }
        }
    },
    mounted() {
        const scene = new THREE.Scene();

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize( this.options.width, this.options.height );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setClearColor( 0x000000, 0 );

        const self = this
        setTimeout(function () {
            const vueRoot = document.querySelector("#model").shadowRoot
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            renderer.toneMapping = THREE.ReinhardToneMapping

            const canvas = vueRoot.querySelector("#render")
            canvas.appendChild(renderer.domElement);


            const camera = new THREE.PerspectiveCamera( 40, self.options.width/self.options.height, 1, 100 );
            camera.position.set( 18, 18, 18 );

            const controls = new OrbitControls( camera, renderer.domElement );
            controls.update();
            controls.enablePan = false
            controls.enableDamping = true
            controls.enableZoom = true
            controls.target.set( 0, 0, 0 );


            const pmremGenerator = new THREE.PMREMGenerator( renderer );

            //Create a DirectionalLight and turn on shadows for the light
            const light = new THREE.DirectionalLight( 0xffffff, 6);
            light.position.set( 10, 10, 10); //default; light shining from top
            light.castShadow = true; // default false

            const side = 20;
            light.shadow.camera.top = side;
            light.shadow.camera.bottom = -side;
            light.shadow.camera.left = side;
            light.shadow.camera.right = -side;
            light.shadow.mapSize.width = 2048; // default
            light.shadow.mapSize.height = 2048; // default
            light.shadow.camera.near = 0.5; // default
            light.shadow.camera.far = 100; // default

            scene.add( light );

            //const shadowHelper = new THREE.CameraHelper( light.shadow.camera );
            //scene.add( shadowHelper );

            //const light = new THREE.AmbientLight( 0x404040 ); // soft white light
            //scene.add( light );
            //const hemisphereLight = new THREE.HemisphereLight(0x404040, 0xFFFFFF, 0.5);
            //scene.add(hemisphereLight);

            scene.background = null
            scene.environment = pmremGenerator.fromScene( new RoomEnvironment( renderer ), 0.1 ).texture;

            const loader = new GLTFLoader();

            // Load a glTF resource
            loader.load(
                // resource URL
                `http://localhost:8001/models/${self.model}.glb`,
                // called when the resource is loaded
                function ( gltf ) {
                    console.log("Loaded!")
                    scene.add( gltf.scene );

                    if (self.model === "piva") {
                        scene.rotateY(-90 - 45)
                    }

                    scene.traverse(function (mesh) {
                        if(mesh instanceof THREE.Mesh){
                            mesh.castShadow = true
                            mesh.receiveShadow = false
                        }
                    })

                    const ground = scene.getObjectByName('Ground')
                    ground.children.forEach(child => {
                        child.castShadow = false;
                        child.receiveShadow = true;
                    })

                    if (self.model === "piva") {
                        scene.rotateY(-90 - 45)
                        controls.target.set(ground.position.x, ground.position.y, ground.position.z);
                    }

                },
                // called while loading is progressing
                function ( xhr ) {

                    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

                },
                // called when loading has errors
                function ( error ) {

                    console.log( 'An error happened', error);

                }
            );

            let intersects = []
            const raycaster = new THREE.Raycaster()
            const mouse = new THREE.Vector2()

            canvas.addEventListener('mousemove', (e) => {
                mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
                mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;
            })

            canvas.addEventListener('click', (e) => {
                //console.log('click', intersects.map(el => el.object.name))
                intersects.forEach((hit) => {
                    // Call onClick
                    if (hit.object.onClick) hit.object.onClick(hit)
                })
            })


            function animate() {

                requestAnimationFrame( animate );

                //const delta = clock.getDelta();

                //mixer.update( delta );

                camera.rotation.set(Math.PI / -2, 0, 0)
                camera.updateMatrixWorld();

                raycaster.setFromCamera(mouse, camera)
                intersects = raycaster.intersectObjects(scene.children, false)

                controls.update();

                renderer.render( scene, camera );

            }

            animate()

        }, 2000)

    },
    props: {
        model: {
            type: String,
            default: "hotel"
        }
    }
};
</script>

<style>
@import "http://127.0.0.1:8000/css/app.css?v=64d5f3d63ec6f";
</style>
