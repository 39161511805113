<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div :class="opened ? 'mb-6' : 'mb-2'">

        <TechnologyTitle title="FWA - Wireless" :type="type" :url="url" />
        <div v-if="opened" >
            <TechnologyHeader :columns="['Copertura', 'Stato', 'Link']"/>

            <div class="columns is-multiline is-vcentered coverage-row" :key="techName" v-for="techName in technologyNames">
                <div class="column">
                    <div style="display: flex; justify-content: center; gap: 10px">
                        <img :src="groupedTechnologies[techName][0].agcom_sticker_url" style="width: 19px; height: 19px; margin: auto 0">
                        <p class="coverage-technology-name">
                            Wireless
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="coverage-tag coverage-tag-success" v-if="somethingActive(groupedTechnologies[techName])">
                        DISPONIBILE
                    </div>
                    <div class="coverage-tag coverage-tag-danger" v-else>
                        NON DISPONIBILE
                    </div>
                </div>
                <div class="column">
                    <a class="coverage-technology-link" @click="$emit('showMap', groupedTechnologies[techName].find(el => el.data && el.data.length > 0))">Mappa delle Antenne</a>
                </div>
            </div>

            <!--<CoverageFullInfo :data="data.map(el => {
                el = {...el}
                delete el.agcom_sticker_url
                el.carrier = el.carrier.name
                el.technology = el.technology.family.description + ' | ' + el.technology.description

                return el
            })"/>-->


        </div>
    </div>
</template>

<script>
import technologies from "@/helpers/coverage/coverageResults/technologies";

export default {
    name: "WirelessResult",
    mixins: [technologies]
}
</script>

<style scoped>

</style>
