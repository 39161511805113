import { render, staticRenderFns } from "./FiberdroidQuiz.vue?vue&type=template&id=68447f0c&shadow"
import script from "./FiberdroidQuiz.vue?vue&type=script&lang=js&shadow"
export * from "./FiberdroidQuiz.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style0 = require("./FiberdroidQuiz.vue?vue&type=style&index=0&id=68447f0c&prod&lang=css&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports