import { render, staticRenderFns } from "./GResult.vue?vue&type=template&id=2bc2c83e&scoped=true&"
import script from "./GResult.vue?vue&type=script&lang=js&"
export * from "./GResult.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2bc2c83e",
  null
  ,true
)

export default component.exports