<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="modal" :class="{'is-active': opened}">
        <div class="modal-background blur-bg" @click="$emit('close')"></div>
        <div class="modal-content gmap-container">
            <GmapMap
                :center="{lat: geodata.lat, lng: geodata.lng}"
                :zoom="zoom"
                map-type-id="roadmap"
                :class="isCabinetsMap() ? 'gmap' : 'gmap-wireless'"
                :options="mapStyle"
                ref="map"
            >
                <GmapMarker
                    :position="markerHome.position"
                    :icon="markerHome.icon"
                    @click="showHomeMarker = true"
                />
                <gmap-info-window
                    @closeclick="showHomeMarker=false"
                    :opened="showHomeMarker"
                    :position="markerHome.position"
                    :options="{
                      pixelOffset: {
                        width: 0,
                        height: -35
                      }
                    }"
                >
                    Indirizzo selezionato
                </gmap-info-window>

                <GmapMarker
                    :key="index"
                    v-for="(cabinet, index) in cabinets"
                    :position="{lat: cabinet.point.lat, lng: cabinet.point.lng}"
                    :icon="{url: activeCabinetId === cabinet.id ? `${url}/pages/cabinet.png` : `${url}/pages/cabinet-off.png`}"
                    :clickable="true"
                    @click="selectedCabinet = index"
                >
                    <gmap-info-window
                        @closeclick="selectedCabinet = null"
                        :opened="selectedCabinet === index"
                        :position="{lat: cabinet.point.lat, lng: cabinet.point.lng}"
                        :options="{
                          pixelOffset: {
                            width: 0,
                            height: 0
                          }
                        }"
                    >
                        {{ cabinet.type | capitalize }} | {{ cabinet.point.particella }} {{ cabinet.point.via }} {{ cabinet.point.civico }}
                    </gmap-info-window>
                </GmapMarker>

                <!--
                CENTRALS
                <GmapMarker
                    :key="index"
                    v-for="(central, index) in centrals"
                    :position="{lat: central.point.lat, lng: central.point.lng}"
                    :icon="{url: 'http://localhost:8001/pages/home.png'}"
                    :clickable="true"
                    @click="showMarkersCentrals.push(index)"
                >
                    <gmap-info-window
                        @closeclick="showMarkersCentrals = showMarkersCentrals.filter(el => el !== index)"
                        :opened="showMarkersCentrals.includes(index)"
                        :position="{lat: central.point.lat, lng: central.point.lng}"
                        :options="{
                          pixelOffset: {
                            width: 0,
                            height: 45
                          }
                        }"
                    >
                        {{ central.type | capitalize }} | {{ central.point.indirizzo.replace("->", "") }}
                    </gmap-info-window>
                </GmapMarker>
                -->

                <GmapMarker
                    :key="'antenna_'+index"
                    v-for="(antenna, index) in bts"
                    :position="{lat: antenna.lat, lng: antenna.lng}"
                    :icon="{url: antenna.covered && !disableBts ? `${url}/pages/antenna.png` : `${url}/pages/antenna-off.png`}"
                    :clickable="true"
                    @click="selectedAntenna = index"
                >
                    <gmap-info-window
                        @closeclick="selectedAntenna = null"
                        :opened="selectedAntenna === index"
                        :position="{lat: antenna.lat, lng: antenna.lng}"
                        :options="{
                          pixelOffset: {
                            width: 0,
                            height: 0
                          }
                        }"
                    >
                        <p>{{ antenna.nome }}</p>

                        <CoverageButtonMapAction
                            @click.native="toggleBtsCoverage(antenna)"
                            :classes="showBtsRadiusIds.includes(antenna.id) ? `is-fd-primary` : `is-dark`"
                            icon="wireless.svg"
                        >
                            Mostra Copertura
                        </CoverageButtonMapAction>

                        <CoverageButtonMapAction
                            @click.native="toggleBtsConnection(antenna)"
                            :classes="showBtsConnectionIds.includes(antenna.id) ? `is-fd-primary` : `is-dark`"
                            icon="cloud-wifi.svg"
                        >
                            Mostra Collegamento
                        </CoverageButtonMapAction>

                        <CoverageButtonMapAction
                            @click.native="showElevationProfile(antenna)"
                            classes="is-warning"
                            icon="mountain.png"
                            big-icon
                        >
                            Profilo Altimetrico
                        </CoverageButtonMapAction>
                    </gmap-info-window>
                </GmapMarker>
                <GmapCircle
                    :key="'circle_'+index"
                    v-for="(antenna, index) in bts"
                    v-if="showBtsRadiusIds.includes(antenna.id)"
                    :radius="antenna.radius"
                    :center="{lat: antenna.lat, lng: antenna.lng}"
                />

                <GmapPolyline
                    :key="'connection_'+index"
                    v-for="(antenna, index) in bts.filter(el => el.covered)"
                    v-if="showBtsConnectionIds.includes(antenna.id)"
                    :path="[
                    markerHome.position,
                    {lat: antenna.lat, lng: antenna.lng},
                    ]" :options="{
                        strokeColor: '#0085FF',
                        strokeWeight: 1,
                        geodesic: true
                    }">

                </GmapPolyline>

            </GmapMap>
            <div class="box" v-if="!isCabinetsMap()" style="background: black">
                <div v-if="!showingElevationProfile">
                    <TechnologyHeader :columns="['Nome', 'Vel. Max.', 'Vel. Max. P2P', 'Altitudine', 'Distanza', '']"/>

                    <div class="columns has-text-centered" :key="index" v-for="(antenna, index) in bts.filter(el => el.covered)">
                        <div class="column">
                            {{ antenna.nome }}
                        </div>
                        <div class="column">
                            {{ antenna.max_speed }} Mbit/s
                        </div>
                        <div class="column">
                            {{ antenna.max_speed_p2p }} Mbit/s
                        </div>
                        <div class="column">
                            {{ antenna.altitude }} metri
                        </div>
                        <div class="column">
                            {{ antenna.distance | distance }}
                        </div>
                        <div class="column">
                            <CoverageButtonAction
                                @click.native="toggleBtsCoverage(antenna)"
                                :classes="showBtsRadiusIds.includes(antenna.id) ? `is-fd-primary` : `is-dark`"
                                icon="wireless.svg"
                                v-tooltip="'Mostra Copertura'"
                            />

                            <CoverageButtonAction
                                @click.native="toggleBtsConnection(antenna)"
                                :classes="showBtsConnectionIds.includes(antenna.id) ? `is-fd-primary ml-2` : `is-dark ml-2`"
                                icon="cloud-wifi.svg"
                                v-tooltip="'Mostra Collegamento'"
                            />

                            <CoverageButtonAction
                                @click.native="showElevationProfile(antenna)"
                                classes="is-warning ml-2"
                                icon="mountain.png"
                                v-tooltip="'Profilo Altimetrico'"
                                big-icon
                            />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="!loadingElevationProfile">
                        <p class="coverage-technology-link" @click="showingElevationProfile = false">Torna indietro</p>
                        <p class="has-text-centered coverage-technology-title is-size-3">Profilo Altrimetrico</p>
                        <p class="has-text-centered coverage-technology-title mt-4">BTS {{ elevationProfileAntenna.nome }} => {{ geodata.street_type | capitalize }} {{ geodata.street | capitalize}}, {{ geodata.name_utf8 | capitalize}}</p>
                        <ElevationProfile :loading="loadingElevationProfile" :data="elevationProfile.results" />
                    </div>
                    <div v-else>
                        <div class="coverage-loading">
                            <div style="display: inline; margin: auto; width: 70%; text-align: center">
                                <p class="subtitle-text">Calcolando il profilo altimetrico...</p>
                                <progress class="progress is-fd-primary is-small mt-4" max="100">20%</progress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import {gmapApi} from 'vue2-google-maps'
import axios from "axios";
import localCoverageData from "@/helpers/coverage/localCoverageData";
import TechnologyHeader from "@/helpers/coverage/coverageResults/template/TechnologyHeader";
import CoverageButtonMapAction from "@/helpers/coverage/CoverageButtonMapAction";
import CoverageButtonAction from "@/helpers/coverage/CoverageButtonAction";
import VTooltip from 'v-tooltip'
import ElevationProfile from "@/helpers/coverage/ElevationProfile";

Vue.use(VTooltip)
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAbuozug8J7bqxWOO0EjELFlmKPsG0csFI',
        //libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        libraries: 'places,drawing,visualization',
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
        map_ids: 'f422d0d16a089382',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    installComponents: true,
})

export default {
    name: "CoverageMap",
    components: {ElevationProfile, CoverageButtonAction, CoverageButtonMapAction, TechnologyHeader},
    props: {
       opened: {
          type: [String, Boolean],
          default: false,
       },
        geodata: {
            type: Object,
            required: true,
        },
        btsIds: {
            type: Array,
            default: () => []
        },
        disableBts: {
            type: Boolean,
            default: false,
        },
        activeCabinetId: {
            type: Number,
            default: -1,
        },
        url: {
            type: String,
            required: true,
        }
    },
    computed: {
        google: gmapApi,
        markerHome: function () {
            return {
                position: {
                    lat: this.geodata.lat,
                    lng: this.geodata.lng,
                },
                icon: {
                    url: `${this.url}/pages/home.png`,
                },
            }
        }
    },
    data() {
        return {
            zoom: 17,
            mapStyle: {
                // other properties...
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#181818"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#1b1b1b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#2c2c2c"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#8a8a8a"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#373737"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#3c3c3c"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#4e4e4e"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3d3d3d"
                            }
                        ]
                    }
                ],
                minZoom: 14,
                maxZoom: 18,
                fullscreenControl: false
            },
            markers: [{
                position: {
                    lat: 10.0,
                    lng: 10.0
                }
            }, {
                position: {
                    lat: 11.0,
                    lng: 11.0
                }
            }],
            cabinets: [],
            centrals: [],
            bts: [],

            showBtsRadiusIds: [],
            showBtsConnectionIds: [],

            showHomeMarker: false,
            showMarkers: [], //@deprecated
            showMarkersCentrals: [],

            selectedCabinet: null,
            selectedAntenna: null,

            elevationProfile: null,
            elevationProfileAntenna: null,
            showingElevationProfile: false,
            loadingElevationProfile: false,
        }
    },
    methods: {
        async api(path) {
            const response = await axios.get(`${this.url}/api/coverage${path}`)

            return response.data
        },
        isCabinetsMap() {
            return this.opened === "cabinets"
        },
        haversineDistance(from, to) {
            const lat1 = from.lat
            const lon1 = from.lng

            const lat2 = to.lat
            const lon2 = to.lng

            const R = 6371e3; // metres
            const phi_1 = lat1 * Math.PI/180; // φ, λ in radians
            const phi_2 = lat2 * Math.PI/180;
            const delta_phi = (lat2-lat1) * Math.PI/180;
            const delta_lambda = (lon2-lon1) * Math.PI/180;

            const a = Math.sin(delta_phi/2) * Math.sin(delta_phi/2) +
                Math.cos(phi_1) * Math.cos(phi_2) *
                Math.sin(delta_lambda/2) * Math.sin(delta_lambda/2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

            return R * c; // in metres
        },
        isBtsCoverageShowed(antenna) {
            return this.showBtsRadiusIds.includes(antenna.id)
        },
        isBtsConnectionShowed(antenna) {
            return this.showBtsConnectionIds.includes(antenna.id)
        },
        toggleBtsCoverage(antenna) {
            if (this.isBtsCoverageShowed(antenna)) {
                this.showBtsRadiusIds = this.showBtsRadiusIds.filter(id => id !== antenna.id)
            } else {
                this.showBtsRadiusIds.push(antenna.id)
            }
        },
        toggleBtsConnection(antenna) {
            if (this.isBtsConnectionShowed(antenna)) {
                this.showBtsConnectionIds = this.showBtsConnectionIds.filter(id => id !== antenna.id)
            } else {
                this.showBtsConnectionIds.push(antenna.id)
            }
        },
        async showElevationProfile(antenna) {
            this.showingElevationProfile = true;
            this.loadingElevationProfile = true;
            this.elevationProfileAntenna = antenna
            this.elevationProfile = await this.api(`/elevation/${antenna.lat}/${antenna.lng}/${this.markerHome.position.lat}/${this.markerHome.position.lng}`)
            this.loadingElevationProfile = false;
        }
    },
    async mounted() {
        //const bounds = await this.api(`/boundaries/${this.geodata.lat}/${this.geodata.lng}/1000`)
        //const cabinets = await this.api(`/cabinets/tim/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)
        //const cabinets = await this.api(`/cabinets/fastweb/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)
        //const cabinets = await this.api(`/cabinets/openfiber/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)
        //const centrals_tim = await this.api(`/centrals/tim/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)
        //const centrals_fastweb = await this.api(`/centrals/fastweb/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)

        let map;
        const self = this

        /**
         * Creates a control that recenters the map on Chicago.
         */
        function createCenterControl(map) {
            const controlButton = document.createElement("button");

            // Set CSS for the control.
            controlButton.style.backgroundColor = "#fff";
            controlButton.style.border = "2px solid #fff";
            controlButton.style.borderRadius = "3px";
            controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
            controlButton.style.color = "rgb(25,25,25)";
            controlButton.style.cursor = "pointer";
            controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
            controlButton.style.fontSize = "16px";
            controlButton.style.lineHeight = "38px";
            controlButton.style.margin = "8px 8px 8px 8px";
            controlButton.style.padding = "0 5px";
            controlButton.style.textAlign = "center";
            controlButton.textContent = "Chiudi Mappa";
            controlButton.title = "Click to close the map";
            controlButton.type = "button";

            controlButton.addEventListener("click", () => {
                self.$emit('close')
            });

            return controlButton;
        }

        // Create the DIV to hold the control.
        const centerControlDiv = document.createElement("div");
        // Create the control.
        const centerControl = createCenterControl(map);

        // Append the control to the DIV.
        centerControlDiv.appendChild(centerControl);

        this.$refs.map.$mapPromise.then((map) => {
            map.controls[this.google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
            this.init();
        });

    },
    watch: {
      opened: {
          handler: async function (opened) {
              if (this.isCabinetsMap()) {
                  this.mapStyle.minZoom = 15
                  this.zoom = 17

                  const bounds = await this.api(`/boundaries/${this.geodata.lat}/${this.geodata.lng}/1000`)
                  const cabinets_tim = await this.api(`/cabinets/tim/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)
                  const cabinets_fastweb = await this.api(`/cabinets/fastweb/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)
                  //const cabinets_openfiber = await this.api(`/cabinets/openfiber/${bounds.north}/${bounds.south}/${bounds.east}/${bounds.west}/`)

                  //const {cabinets, centrals} = this.getData()
                  this.cabinets = [...cabinets_tim, ...cabinets_fastweb].filter(el => el.point.stato_fttc === "ATTIVO")

                  //this.centrals = centrals
                  this.bts = []
              }
              else {
                  this.mapStyle.minZoom = 7
                  this.zoom = 11

                  const {bts} = this.getBtsData()
                  //const bts = await this.api(`/bts`)

                  this.bts = bts
                      .map(el => {
                          el.distance = this.haversineDistance(this.markerHome.position, {
                              lat: el.lat, lng: el.lng
                          })
                          return el
                      })
                      .filter(el => this.btsIds.includes(el.id) || el.distance < 20*1000).map(el => {
                      el.distance = this.haversineDistance(this.markerHome.position, {
                          lat: el.lat, lng: el.lng
                      })

                      el.radius = this.haversineDistance({
                          lat: el.geo_north, lng: el.lng
                      }, {
                          lat: el.geo_south, lng: el.lng
                      }) / 2

                      el.covered = el.distance < el.radius

                      return el
                  }).sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance))

                  if (!this.disableBts) {
                      this.showBtsConnectionIds = this.bts.filter(el => el.covered).map(el => el.id)
                  } else {
                      this.showBtsConnectionIds = []
                  }

                  this.cabinets = []
                  this.centrals = []
              }

              if (opened) {
                  document.getElementsByTagName('html')[0].style.overflow = "hidden";
              } else {
                  document.getElementsByTagName('html')[0].style.overflow = "auto";
              }

              this.$refs.map.$mapPromise.then((map) => {
                  map.panTo({lat: this.geodata.lat, lng: this.geodata.lng})
              })
          }
      }
    },
    filters: {
        capitalize: function (value) {
            const capitalize_single = (value) => {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            }

            const words = value.split(" ")

            return words.map(el => capitalize_single(el)).join(" ")
        },
        distance: function (value) {
            if (value >= 1000) {
                return Math.round(value / 1000.0) + " chilometri"
            } else if (this >= 100) {
                return Math.round(value) + " metri"
            } else {
                return value.toFixed(1) + " metri"
            }
        }
    },
    mixins: [localCoverageData]
}
</script>

<style scoped>

</style>
