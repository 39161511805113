<template>
    <div class="mt-2" style="display: flex; gap: 11px">
        <button
            class="button"
            style="width: 32px; height: 32px; border: none"
            :class="classes"
        >
                                <span class="icon is-small">
                                  <img :src="`http://127.0.0.1:8001/pages/menu/${icon}`" :style="bigIcon ? `max-width: 25px` : `max-width: 15px`">
                                </span>
        </button>
        <p style="margin: auto 0; color: black" class="coverage-technology-link">
            <slot />
        </p>
    </div>
</template>

<script>

export default {
    name: "CoverageButtonMapAction",
    props: {
        classes: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        bigIcon: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>

</style>
