var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",class:[
                            `quiz-option-box-shadow${_vm.size}`,
                            `quiz-gradient-${_vm.option.gradient}`,
                            {
                                [`quiz-gradient-${_vm.option.gradient}-active`] : _vm.active,

                                'ml-auto': _vm.mlAuto,
                                'mx-auto': (_vm.size === '-small' || _vm.size === '-medium') && _vm.index % 3 === 1,
                                'mr-auto': _vm.mrAuto,
                            }
                        ]},[_c('div',{staticClass:"box",class:`quiz-option-box${_vm.size}`},[_c('div',{class:`quiz-option-wrapper${_vm.size}`},[_c('div',[_c('img',{staticClass:"quiz-option-image",attrs:{"src":`https://stage.fiberdroid.it/pages/quiz/${_vm.option.icon}.svg`}}),_c('p',{staticClass:"quiz-text-option",domProps:{"innerHTML":_vm._s(_vm.option.label)}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }