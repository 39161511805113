<template>
    <div class="box quiz-gradient" :style='`background: ${bg};`'>
        <div class="box quiz-box">
            <div class="columns is-multiline px-6-desktop px-3-mobile pt-6 pb-0" style="height: 100%">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShadowBox",
    props: {
        bg: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>

</style>
