<template>
    <div id="chart"></div>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
    name: "ElevationProfile",
    mounted() {
        const options = {
            chart: {
                type: 'area',
                width: '1100px',
                height: '400px',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
            },
            series: [{
                name: 'Altitude',
                data: this.data.map(el => el.elevation)
            }],
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                categories: this.data.map(el => el.offset),
                tickAmount: 10,
                labels: {
                    formatter: function (value) {
                        value = parseFloat(value)

                        if (value >= 1000) {
                            return (value / 1000.0).toFixed(2) + " km"
                        } else if (this >= 100) {
                            return value.toFixed(2) + " m"
                        } else {
                            return value.toFixed(2) + " m"
                        }
                    }
                },
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: false,
                },
                distributed: true,
            },
            yaxis: {
                min: 0,
                max: 250,
                tickAmount: 4,
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
        }

        const sorted = this.data.map(el => el.elevation).sort((a,b) => a - b)
        const min = sorted[0];
        const max = sorted[sorted.length - 1]

        options.yaxis.max = max + 50
        options.yaxis.min = min - 50

        if (options.yaxis.min < 0) {
            options.yaxis.min = 0
        }

        const vueRoot = document.querySelector("#verifica-copertura").shadowRoot
        const chart = new ApexCharts(vueRoot.querySelector("#chart"), options);

        chart.render();
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
    }
}
</script>

<style scoped>

</style>
