<template>
    <div class="columns is-mobile" v-if="info">
        <div class="column is-9" style="display: flex; gap: 20px; border-bottom: 0.71px solid #323338;">
            <div class="figure" style="width: 19px; height: 19px">
                <img :src="`${this.url}/pages/coverage/${info.icon}`">
            </div>

            <span style="margin: auto 0" class="coverage-service-text my-auto">{{ info.name }}</span>
            <!--<span style="margin: auto 0" class="coverage-provider-text my-auto pl-5">{{ providers.join(", ") }}</span>-->
        </div>
        <div class="column is-3" style="display: flex; margin-left: auto">
            <div class="coverage-connected-lamp-shadow" :class="anythingActive ? 'is-green' : 'is-red'">
                <span class="coverage-connected-lamp" :class="anythingActive ? 'lamp-green' : 'lamp-red'"/>
            </div>

            <span style="margin: auto 0;" class="coverage-connected-text pl-3">
                {{ anythingActive ? "Disponibile" : "Non Disponibile"}}
            </span>
        </div>
    </div>
</template>

<script>
import technologies from "@/helpers/coverage/coverageResults/technologies";

export default {
    name: "CoverageResult",
    props: {
        url: {
            type: String,
            required: true,
        },
        info: {
            type: Object,
            required: true
        },
        providers: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: false,
        },
        forceActive: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [technologies],
    computed: {
        anythingActive: function() {

            if (this.forceActive) return true

            let result = false

            for (const techName in this.groupedTechnologies) {
                if (this.somethingActive(this.groupedTechnologies[techName])) {
                    result = true

                    return result
                }
            }

            return result
        }
    }
}
</script>
