<template>
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label coverage-input-label">{{ label }}</label>
        </div>
        <div class="field-body">
            <div class="field">
                <p class="control">
                    <!--<input class="input coverage-input" @input="$emit('input', $event.target.value)">-->
                    <multiselect
                        v-model="selected"
                        :options="options"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        @select="onValueSelected"
                        @search-change="searchChanged"
                        :internal-search="!disableSearch"
                        :ref="name"
                    >
                    </multiselect>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "CoverageInput",
    components: {Multiselect},
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableSearch: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            selected: null,
        }
    },
    methods: {
        onValueSelected(value) {
            this.$emit('input', value)
        },
        searchChanged(query) {
            this.$emit('search-changed', query)
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
