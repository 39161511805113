<template>
    <div class="columns coverage-header hidden-mobile">
        <div class="column" :key="column" v-for="column in columns">{{ column }}</div>
    </div>
</template>

<script>
export default {
    name: "TechnologyHeader",
    props: {
        columns: {
            type: Array,
            required: true
        },
    },
    computed: {
        columnSize: function () {
            return 12 / this.columns.length
        }
    }
}
</script>

<style scoped>

</style>
