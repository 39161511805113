import { render, staticRenderFns } from "./QuizResultBox.vue?vue&type=template&id=649d574f&scoped=true&"
import script from "./QuizResultBox.vue?vue&type=script&lang=js&"
export * from "./QuizResultBox.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "649d574f",
  null
  ,true
)

export default component.exports