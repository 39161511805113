<template>
  <div>
    <h1>{{ message }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Test!"
    }
  }
};
</script>

<style>
@import "http://127.0.0.1:8000/css/app.css?v=64d5f3d63ec6f";
</style>
