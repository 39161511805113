<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div :class="opened ? 'mb-6' : 'mb-2'">

        <TechnologyTitle :title="data[0].technology.family.description" :type="type" :url="url"/>
        <div v-if="opened">
            <TechnologyHeader :columns="['Copertura', 'Stato', 'Richiesta']"/>

            <div class="columns is-vcentered coverage-row" :key="techName" v-for="techName in technologyNames">
                <div class="column is-4">
                    <div style="display: flex; justify-content: center; gap: 10px">
                        <img :src="groupedTechnologies[techName][0].agcom_sticker_url" style="width: 19px; height: 19px; margin: auto 0">
                        <p class="coverage-technology-name">
                            {{ techName }}
                        </p>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="coverage-tag coverage-tag-success" v-if="somethingActive(groupedTechnologies[techName])">
                        DISPONIBILE
                    </div>
                    <div class="coverage-tag coverage-tag-danger" v-else>
                        NON DISPONIBILE
                    </div>
                </div>
                <div class="column is-4">
                    <div class="button coverage-button is-dark is-small">Invia richiesta</div>
                </div>
                <!-- FASCIA <div class="column is-4">
                    <div class="field is-grouped is-grouped-multiline">
                        <div class="control" :key="technology.carrier.name" v-for="technology in groupedTechnologies[techName]">
                            <div class="tags coverage-tags has-addons">
                                <span class="tag is-dark">{{ technology.carrier.name }}</span>
                                <span class="tag is-info">{{ technology.data.fascia_circuiti_fibra }}</span>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import technologies from "@/helpers/coverage/coverageResults/technologies";

export default {
    name: "FtthResult",
    mixins: [technologies]
}
</script>

<style scoped>

</style>
