<template>
    <button
        class="button"
        style="width: 40px; height: 40px"
        :class="classes"
    >
                                <span class="icon is-small">
                                  <img :src="`http://127.0.0.1:8001/pages/menu/${icon}`" :class="{'absolute': bigIcon}">
                                </span>
    </button>
</template>

<script>
export default {
    name: "CoverageButtonAction",
    props: {
        classes: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        bigIcon: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
    .absolute {
        position: absolute;
    }
</style>
