<template>
    <div
        class="box service-box-gradient"
        style="backdrop-filter: blur(50px); display: flex; height: 100%; background-repeat:no-repeat; background-size: cover; background-image: url('https://stage.fiberdroid.it/pages/gradients/quiz.png')"
    >
        <div class="box service-box-box p-6" style="margin-right: -25px; margin-bottom: -25px; display: flex; flex-direction: column">

        <p class="service-box-title-text is-uppercase mt-4">{{ title }}</p>


        <!-- Bullet List -->
        <div class="mt-auto">
            <div class="my-6 pt-5 pb-0" style="border-bottom: solid 1px rgba(217, 217, 217, 0.3);">
                <div :key="item.name" v-for="item in items" style="display: flex; gap: 14px; padding: 12px 0; border-top: solid 1px rgba(217, 217, 217, 0.3);">
                    <img :src="`https://stage.fiberdroid.it/pages/services/items/${item.icon}`">
                    <p class="quiz-result-box-item has-text-left">{{ item.name }}</p>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <div class="mt-auto">
            <div class="columns is-mobile is-vcentered" style="width: 100%; margin: 0">
                <div class="column is-12 has-text-centered pl-0">
                    <p class="quiz-result-box-price-text">
                        <span>{{ price | formatPrice }} &euro;</span>
                        <span class="is-unselectable">/ mese</span>
                    </p>
                </div>
            </div>
            <div class="columns is-mobile is-vcentered" style="width: 100%; margin: 0">
                <div class="column is-6 has-text-left">
                    <button class="button is-smaller is-dark" data-add-to-cart>
                    <span class="icon">
                       <img src="https://stage.fiberdroid.it/pages/home/icons/edit.svg" class="image" alt="Image 1">
                    </span>
                        <span style="width: 100%; text-align: center">Modifica</span>
                    </button>
                </div>
                <div class="column is-6 has-text-right">
                    <button class="button is-smaller is-fd-primary" data-add-to-cart>
                    <span class="icon">
                       <img src="https://stage.fiberdroid.it/pages/home/icons/cart.svg" class="image" alt="Image 1">
                    </span>
                        <span style="width: 100%; text-align: center">Add To Cart</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: "QuizResultBox",
    props: {
        title: {
            type: String,
            required: true,
        },
        price: {
            type: [String, Number],
            required: true,
        },
        items: {
            type: Array,
            required: true,
        }
    },
    filters: {
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    }
}
</script>

<style scoped>

</style>
