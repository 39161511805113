<template>
    <div class="columns is-mobile mb-0">
        <div v-if="step === 0" class="column quiz-container desktop-11 touch-12 mx-auto mt-6 px-6 pb-6 mt-0 pt-0">
            <div class="box quiz-gradient" :style='`background: ${bg};`'>
                <div class="box quiz-box box-small">
                    <div class="columns p-0">
                        <div class="column is-6 p-6">
                            <p class="text-small pb-4">Hai bisogno di aiuto a scegliere?</p>
                            <p class="title-text pb-4 pt-6-mobile" style="font-weight: 600">
                                Trova la migliore <br>
                                soluzione per il tuo<br>
                                business
                            </p>
                            <p class="text-smaller mb-4">
                                fai il nostro quiz interattivo e lascia che i nostri esperti ti indirizzino alle tecnologie più adatte a te,
                                sulla base delle tue scelte.
                            </p>

                            <button class="button is-small is-fd-primary" style="border-radius: 8px" @click.exact="nextStep" @click.alt="endForAdmin">
                                <b id="start-quiz">INIZIA QUIZ</b>
                            </button>
                            <a target="_blank" href="https://calendly.com/fiberdroid/richiesta-chiamata" class="button quiz-button-down-mobile is-small is-dark ml-4" style="border-radius: 8px">
                                <b class="has-text-white">ORGANIZZA UNA CALL</b>
                            </a>

                        </div>
                        <div class="column is-6 p-0 quiz-image" :style="`background-size: cover; background-image: url('https://stage.fiberdroid.it/pages/${icon}')`">
                            <!--style="background-image: url('https://stage.fiberdroid.it/pages/quiz/digital.png')">!-->
                            <!--https://stage.fiberdroid.it/pages/home/quiz-mask.png !-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="column hidden-desktop quiz-container is-11 mx-auto mt-6 px-6 pb-6 mt-0 pt-0"
             style="position: relative; z-index: 1;">
            <div class="mt-6 hidden-tablet-only hidden-desktop"></div>
            <p class="text-small pb-5 mb-0">Need help deciding? </p>
            <p class="title-text mb-0">
                Let’s find the best <br>
                solution for your <br>
                business
            </p>
            <p class="text-smaller my-5">
                Take our interactive quiz and let our experts navigate you to the most suitable technical stack.
            </p>

            <button class="button is-small is-fd-primary" style="border-radius: 8px">
                <b>START QUIZ</b>
            </button>
            <button class="button is-small is-dark mt-2" style="border-radius: 8px">
                <b>SCHEDULE A CALL</b>
            </button>

            <img src="https://stage.fiberdroid.it/pages/home/fiber.png" class="image" style="visibility: hidden">
            <img src="https://stage.fiberdroid.it/pages/home/fiber.png" class="image"
                 style="z-index: -1; position: absolute; transform: translateY(-100%)">
        </div>-->

        <StepBox
            :bg="bg"
            v-if="step === 1"
            :step="step"
            :options="options"
            :selected="selected"

            @optionClicked="toggleSelect"
            @nextStep="nextStep"
        >
            <template #title>
                <p class="quiz-text-title pb-4">Di quali servizi hai bisogno?</p>
                <p class="quiz-text-title quiz-is-subtitle">(puoi sceglierne più di uno)</p>
            </template>
        </StepBox>

        <StepBox
            :bg="bg"
            v-if="step === 2"
            :step="step"
            :options="options"
            :selected="selected"

            @optionClicked="toggleSelect"
            @nextStep="nextStep"
            @previousStep="previousStep"
        >
            <template #title>
                <p class="quiz-text-title pb-4">Quali funzionalità pensi che userai maggiormente?</p>
                <p class="quiz-text-title quiz-is-subtitle">(puoi sceglierne più di una)</p>
            </template>
        </StepBox>

        <StepBox
            :bg="bg"
            v-if="step === 3"
            :step="step"
            :options="options"
            :selected="selected"

            @optionClicked="toggleSelectSingle"
            @nextStep="nextStep"
            @previousStep="previousStep"
        >
            <template #title>
                <p class="quiz-text-title pb-4">Quanti dipendendenti o clienti <br> si connetteranno ad internet ogni giorno?</p>
            </template>
        </StepBox>

        <StepBox
            :bg="bg"
            v-if="step === 4"
            :step="step"
            :options="options"
            :selected="selected"

            @optionClicked="toggleSelectSingle"
            @nextStep="nextStep"
            @previousStep="previousStep"
        >
            <template #title>
                <p class="quiz-text-title pb-4">Hai bisogno di una linea di backup?</p>
            </template>
        </StepBox>

        <StepBox
            :bg="bg"
            v-if="step === 5"
            :step="step"
            :options="options"
            :selected="selected"

            @optionClicked="toggleSelectSingle"
            @nextStep="nextStep"
            @previousStep="previousStep"
        >
            <template #title>
                <p class="quiz-text-title pb-4">Serve un router o ne possiedi già uno?</p>
            </template>
        </StepBox>

        <StepBox
            :bg="bg"
            v-if="step === 6"
            :step="step"
            :options="options"
            :selected="selected"

            @optionClicked="toggleSelectSingle"
            @nextStep="lastStep"
            @previousStep="previousStep"
        >
            <template #title>
                <p class="quiz-text-title pb-4">Servono dei telefoni o ne possiedi già alcuni?</p>
            </template>
        </StepBox>

        <div class="column is-12 has-text-centered mt-6" v-if="step === 7">
            <p class="quiz-text-title">Ecco i nostri consigli</p>

            <div class="columns my-6" v-if="result">
                <div class="column is-11 mx-auto px-6">
                    <div class="columns">
                        <div class="column is-4" :key="data.name" v-for="(data, index) in result">
                            <QuizResultBox
                                :title="data.name"
                                :items="data.items"
                                :price="data.totalAmount"
                                :class="index === 0 ? 'ml-auto' : (index === 1 ? 'mx-auto' : 'mr-auto')"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <span class="button is-dark" @click="restart">
                Ripeti quiz
            </span>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import StepBox from "@/helpers/quiz/StepBox";
import axios from "axios";
import QuizResultBox from "@/helpers/quiz/QuizResultBox";

export default {
    components: {QuizResultBox, StepBox},
    data() {
        return {
            step: 0,
            options: {
                1: [
                    {label: "Internet", id: "internet", icon: "wifi", gradient: "internet"},
                    {label: "Phone", id: "phone", icon: "phone", gradient: "voip"},
                ],
                2: [
                    {label: "Email/<br>Messaging", id: "email", icon: "paper-plane", bg: "wifi", gradient: "internet", visible: false},
                    {label: "Cloud <br>apps", id: "cloud", icon: "cloud", bg: "wifi", gradient: "internet", visible: false},
                    {label: "Video <br>Conferencing", id: "video", icon: "camera", bg: "wifi", gradient: "internet", visible: false},
                    {label: "File <br>Sharing", id: "file", icon: "file", bg: "wifi", gradient: "internet", visible: false},
                    {label: "Guest and <br>employee wifi", id:"guest-wifi", icon: "wifi", gradient: "internet", visible: false},
                    {label: "VOIP phone <br>service", id:"voip-phone", icon: "phone", gradient: "voip", addLabel: "Centrale Voip Cloud", addIcon: "pbx"},
                ],
                3: [
                    {label: "1-5", power: 0, id:"how-many", icon: "user", gradient: "internet", visible: false},
                    {label: "6-20", power: 1, id:"how-many", icon: "double-user", gradient: "internet", visible: false},
                    {label: "More than 20", power: 2, id:"how-many", icon: "users", gradient: "internet", visible: false},
                ],
                4: [
                    {label: "Yes, thanks", id:"backup", value: true, icon: "backup", gradient: "backup", addLabel: "Linea di Backup"},
                    {label: "No, we good", id:"backup", value: false, icon: "danger", gradient: "backup", visible: false},
                ],
                5: [
                    {label: "Yes, for sure", id:"router", value: true, icon: "router", gradient: "internet", addLabel: "Router"},
                    {label: "No, thanks", id:"router", value: false, icon: "times", gradient: "internet", visible: false},
                ],
                6: [
                    {label: "Yes, please", id:"desk-phone", value: true, icon: "desk-phone", gradient: "internet", visible: false},
                    {label: "No, thank you", id:"desk-phone", value: false, icon: "times", gradient: "internet", visible: false},
                ]
            },
            selected: [],

            result: null,
        }
    },
    props: {
        icon: {
            type: String,
            default: "home/fiber.png"
        },
        gradient: {
            type: String,
            default: "https://stage.fiberdroid.it/pages/home/quiz-mask.png"
        },
        url: {
            type: String,
            default: "http://localhost:8001"
        },
    },
    methods: {
        async quizApi(data) {
            const response = await axios.post(`${this.url}/api/quiz`, {data})

            return response.data
        },
        getServiceName(service, phone, centrale, backup) {
            const service_name = service ? service.name : ''
            const centrale_name = centrale ? centrale.name : ''
            const centrale_prefix = centrale && service ? ' + ' : ''
            const phone_name = phone ? phone.name : ''
            const phone_prefix = phone && centrale || phone && service ? ' + ' : ''
            const backup_name = backup ? backup.name : ''
            const backup_prefix = service && backup || centrale && backup || phone && backup ? ' + ' : ''

            return service_name + centrale_prefix + centrale_name + phone_prefix + phone_name + backup_prefix + backup_name
        },
        async lastStep() {
            this.nextStep()

            const response = await this.quizApi(this.selected)

            this.result = response.map(data => {
                data = data[0]
                const {service, router, phone, centrale, backup, deskPhone, totalAmount} = {...data}
                return {
                    name: this.getServiceName(service, phone, centrale, backup),
                    items: [
                        ...(service ? service.items : []),
                        ...(router ? router.items : []),
                        ...(centrale ? centrale.items : []),
                        ...(phone ? phone.items : []),
                        ...(deskPhone ? deskPhone.items : []),
                        ...(backup ? backup.items : []),
                    ],
                    totalAmount,
                }
            })

            console.log('result', this.result)
        },
        nextStep() {
            this.step = this.step + 1
        },
        previousStep() {
            this.step = this.step - 1
        },
        endForAdmin() {
            this.step = 6

            this.selected = [
                ...this.options[1],
                ...this.options[2]
            ]
            this.lastStep()
        },
        toggleSelect({step, option}) {
            if (!this.isSelected(option)) {
                option.step = step
                this.selected.push(option)
            } else {
                const index = this.selected.findIndex(el => el.label === option.label)

                if (index !== -1) {
                    this.$delete(this.selected, index)
                }
            }
        },
        toggleSelectSingle({step, option}) {
            const options = this.options[step]

            for (const other of options) {
                const index = this.selected.findIndex(el => el.label === other.label)

                if (index !== -1) {
                    this.$delete(this.selected, index)
                }
            }

            option.step = step
            this.selected.push(option)
        },
        isSelected(option) {
            return !!this.selected.find(el => el.label === option.label)
        },
        restart() {
            this.step = 0
            this.selected = []
        },
        checkAll() {
            alert("Coming soon....")
        }
    },
    computed: {
        bg: function () {
            if (this.gradient.startsWith("http")) {
                return `url("${this.gradient}")`
            } else {
                return this.gradient
            }
        }
    }
};
</script>

<style>
@import "https://stage.fiberdroid.it/css/app.css";
</style>
