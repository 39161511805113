import TechnologyHeader from "@/helpers/coverage/coverageResults/template/TechnologyHeader";
import TechnologyTitle from "@/helpers/coverage/coverageResults/template/TechnologyTitle";

export default {
    components: {TechnologyHeader, TechnologyTitle},
    props: {
        url: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        opened: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "",
        }
    },
    methods: {
        somethingActive(data) {
            return !!data.find(el => el.status === 'ATTIVO')
        },
        detailNetworkSpeed(detail) {

            const fttcDown = detail.download.fttc
            const evdslDown = detail.download.evdsl
            const fttcUp = detail.upload.evdsl
            const evdslUp = detail.upload.evdsl


            if (fttcDown == evdslDown && evdslDown == fttcUp && fttcUp == evdslUp && evdslUp == 0) {
                return "-"
            }

            const fttc = `${detail.download.fttc} Mbit/s <span class="mx-3"></span> ${detail.upload.fttc} Mbit/s`
            const evdsl = `${detail.download.evdsl} Mbit/s <span class="mx-3"></span> ${detail.upload.evdsl} Mbit/s`

            return "<p>[fttc]</p><p>[evdsl]</p>"
                .replace("[fttc]", fttc)
                .replace("[evdsl]", evdsl)
        }
    },
    computed: {
        groupedTechnologies: function() {
            const result = {}

            if (!this.data) return null

            for (const tech of this.data) {
                const techName = tech.technology.description

                if(!result[techName]) {
                    result[techName] = [tech]
                } else {
                    result[techName].push(tech)
                }
            }

            return result
        },
        technologyNames: function () {
            return Object.keys(this.groupedTechnologies)
        }
    },
    filters: {
        network_speed: function (value) {
            if (!value) return "";

            value = parseInt(value) * 1000 * 1000
            const units = ["bit/s", "Kbit/s", "Mbit/s", "Gbit/s", "Tbit/s"];
            let k = 0,
                v = parseInt(value, 10) || 0;
            while (v >= 1000 && ++k) {
                v = v / 1000;
            }
            return v.toFixed(v < 10 && k > 0 ? 1 : 0) + " " + units[k];
        },
        network_speed_mbit: function (value) {
            if (!value) return "";

            return value + " Mbit/s";
        },
        distance: function (value) {
            return value ? `${value} m` : "-"
        },
    }
}
